import { Container } from "react-bootstrap";
import "./aboutus.css";
import image1 from "../../assets/pexels-denys-gromov-4781948.jpg";
import image2 from "../../assets/pexels-marcus-herzberg-2086765.jpg";
import image3 from '../../assets/pexels-nextvoyage-1470502.jpg'

const AboutUs = () => {
  return (
    <Container>
      <h2 className="about-page-title">About Us</h2>

      <div className="row">
            <div className="col-12 col-lg-6 align-self-center">
              <h5 className="accent-heading">MA Tours</h5>
              <h2 className="primary-heading">
                About MA <span className="text-primary">Travel</span> and <span className="text-primary">Tourism</span>
              </h2>
              <p className="fs-5">
              Welcome to MA Travel and Tourism, your gateway to unforgettable journeys across Dubai, Abu Dhabi, and Ras Al Khaimah. As a premier travel company rooted in Dubai, we curate exceptional experiences that reflect the essence of these vibrant destinations. Our expert team is dedicated to crafting seamless travel adventures, from exploring the iconic cityscapes to immersing in the rich cultures and breathtaking landscapes. Embark on a remarkable voyage with us and let MA Travel and Tourism redefine your travel aspirations. Your journey begins here.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="imageContainer">
                <img
                  className={`decorImages decorImages1`}
                  src={image1}
                  alt=""
                />
                <img
                  className={`decorImages decorImages2`}
                  src={image2}
                  alt=""
                />
                <img
                  className={`decorImages decorImages3`}
                  src={image3}
                  alt=""
                />
              </div>
            </div>
          </div>

    </Container>
  );
};
export default AboutUs;
