import { useEffect, useState } from "react"
import { Carousel, Container } from "react-bootstrap"
import { useParams } from "react-router-dom"
import axios from "../../api/axios";
import './serviceDetails.css';
import PopoverPositioned from "../../components/Popover/Popover";
import {FaRegClock} from 'react-icons/fa'
const ServiceDetails = () => {
  const {id} = useParams();
  const [service, setService] = useState({});

  useEffect(() => {
    const fetchData = async() =>{
      const response = await axios(`/api/services/${id}`);
      console.log(response)
      setService(response.data.data)
      console.log(" api called");
    }
    fetchData();
  }, [id])
  return (
    <section className="service-section">
      <Container>
        <div className="d-flex flex-column gap-3">
          <div className="w-100">
            <Carousel>
              {service?.images?.map(image => (
                <Carousel.Item key={`image_${image?.id}`}>
                  <img className="carouselImage" src={`${process.env.REACT_APP_API_BASE_URL}${image?.image}`} alt="" />
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="titleContainer cardShape">
              <h1 className="serviceTitle mb-0">{service.title}</h1>
              <a href={`/booking?service_id=${id}`} className="btn btn-primary">Book Now</a>
            </div>
          </div>
          <div className="service-facilities cardShape">
            <PopoverPositioned placement={"top"} id={1} popoverText={"8.00 PM to 09:30 pm"}>
              <div className="d-flex align-items-center gap-2">
                <FaRegClock />
                <span>Operating Hours</span>
              </div>
            </PopoverPositioned>

            <PopoverPositioned placement={"top"} id={2} popoverText={"Instant Tour Confirmation will be Provided"}>
              <div className="d-flex align-items-center gap-2">
                <FaRegClock />
                <span>Instant Confirmation</span>
              </div>
            </PopoverPositioned>
          </div>
          <div className="experience cardShape">
            <h2 className="cardHeader">About Experience</h2>
            {service.long_description}
          </div>
          <div className="highlights cardShape">
            <h2 className="cardHeader">Highlights</h2>
            <ul className="list highlights-list">
              {service?.highlights?.map(highlight => (
                <li key={`highlight_${highlight.id}`} className="highlight-item">{highlight.title}</li>
              ))}
            </ul>
          </div>
          <div className="inclusion cardShape">
            <h2 className="cardHeader">Tour Inclusions</h2>
            <ul className="list inclusion-list">
              {service?.inclusions?.map(inclusion => (
                <li key={`inclusion_${inclusion.id}`} className="inclusion-item">{inclusion.title}</li>
              ))}
            </ul>
          </div>
          <div className="informations cardShape">
            <h2 className="cardHeader">Important Information</h2>
            <ul className="list informations-list">
              {service?.informations?.map(information => (
                <li key={`information_${information.id}`} className="information-item">{information.title}</li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </section>
  )
}
export default ServiceDetails