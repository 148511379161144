import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Service from "./pages/Services/Service";
import ContactUs from "./pages/ContactUs/ContactUs";
import Tour from "./pages/Tour/Tour";
import AboutUs from "./pages/AboutUs/AboutUs";
import Booking from "./pages/Booking/Booking";
import ServiceDetails from "./pages/ServiceDetails/ServiceDetails";
import ServiceByCity from "./pages/ServiceByCity/ServiceByCity";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Service />} />
        <Route path="/service/:id" element={<ServiceDetails />} />
        <Route path="/:title/:id" element={<ServiceByCity />} />
        <Route path="/tour" element={<Tour />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/booking" element={<Booking />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
