import classes from "./topBanner.module.css";
import {
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaInstagram,
  FaRegClock,
  FaPhoneVolume,
  FaEnvelope,
} from "react-icons/fa";

const TopBanner = () => {
  return (
    <div className={classes.topBanner}>
      <div className="container-fluid container-lg">
        <div className={classes.content}>
          <ul className={classes.socialList}>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="https://www.facebook.com/profile.php?id=61550280841388&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                <FaFacebookF />
              </a>
            </li>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="https://www.twitter.com/" target="_blank" rel="noreferrer">
                <FaTwitter />
              </a>
            </li>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="https://www.google.com" target="_blank" rel="noreferrer">
                <FaGooglePlusG />
              </a>
            </li>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="https://www.instagram.com/ma.travelandtourism/" target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
            </li>
          </ul>
          <ul className={classes.contactList}>
            <li className={classes.contactItem}>
              <a className={`link ${classes.contactLink}`} href="tel:+971 56 3725439">
                <FaPhoneVolume />
                <span>+971 56 3725439</span>
              </a>
            </li>
            {/* <li className={classes.contactItem}>
              <a className={`link ${classes.contactLink}`} href="">
                <FaPhoneVolume />
                <span>+971 55 545454</span>
              </a>
            </li> */}
            <li className={classes.contactItem}>
              <a className={`link ${classes.contactLink}`} href="mailto:abidzahid979@gmail.com">
                <FaEnvelope />
                <span>abidzahid979@gmail.com</span>
              </a>
            </li>
            <li className={classes.contactItem}>
              <div>
                <FaRegClock />
                <span>We're here for you 24/7</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TopBanner;
