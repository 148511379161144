import { Container } from "react-bootstrap";
import "./booking.css";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Booking = () => {
  const [name, setName]= useState('');
  const [email, setEmail]= useState('');
  const [phone, setPhone]=useState('');
  const [serviceId, setServiceId] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage]= useState('');
  const [transferType, setTransferType] = useState("");
  const [services, setServices] = useState([]);
  const [isBooking, setIsBooking] = useState(false);
  const [isError, setIsError] = useState(false);
  const serviceAPI = "api/service-list";
  const bookingAPI = "api/bookings";

  // const {service_id} = useParams()


  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setTransferType("");
    setServiceId("");
    setSubject("");
    setMessage("");
  }

  useEffect(() => {
    if(!name || !email || !phone || !serviceId || !subject || !message ){
      setIsError(true);
    }
    else{
      setIsError(false);
    }
  }, [name, email, phone, serviceId, subject, message])

  useEffect(() => {
    const fetchServices = async () => {
      const response = await axios.get(serviceAPI)

        setServices(response.data.data)
        // console.log(response.data.data);
      
    }
    fetchServices();
  }, [serviceAPI]);
  // console.log(service_id);

  const submitHandler = async(e) => {
    e.preventDefault();
    setIsBooking(true)
    const formData = {
      name: name,
      email: email,
      phone: phone,
      service_id: serviceId,
      transfer_type: transferType,
      subject: subject,
      message: message,
      tour_options: []
    };
    console.log(formData)
    console.log(transferType);
    try{

      const response = await axios.post(bookingAPI, formData);

      console.log(response.data);
      if(response?.data?.status === true){
        toast.success("Your tour is Booked!")
      }
    }catch(error){
      console.log(error);
      toast.error(error.response?.data?.message)
    }
    setIsBooking(false)
    resetForm();
  }

  return (
    <section className="section">
      <Container>
        <div className="row gap-4 gap-lg-2">
          <div className="col-12 col-lg-5">
            <div className="contact-us-info">
              <div>
                <h3>Operating Hours:</h3>
                <p>We're here for you 24/7</p>
              </div>
              <div>
                <h3>ADDRESS:</h3>
                <p>
                P.O. box 93512,
                Deira, Dubai, Horal anz.
                </p>
              </div>
              <div>
                <h3>TICKET INFO:</h3>
                <div className="d-flex flex-column gap-2">
                  <div className="contact-links-container">
                    <p>Phone:</p>
                    <a className="link contact-link" href="tel:+971 56 372 5439">+971 56 372 5439</a>
                  </div>
                  <div className="contact-links-container">
                    <p>Email:</p>
                    <a className="link contact-link" href="mailto:abidzahid979@gmail.com">abidzahid979@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="contact-us-form">
              <h2 className="mb-0 primary-heading">Book your Tour</h2>
              <p className="mb-0">
                Secure your seamless and reliable travel experience now! Indulge in unparalleled service with our premium tours, enhancing your travel and tour adventures.
              </p>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="fullNameInput" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullNameInput"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="emailInput" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="phoneInput" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneInput"
                        placeholder="Enter your phone no."
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="subjectInput" className="form-label">
                        Tour
                      </label>
                      <select 
                        id="subjectInput"
                        className="form-select"
                        aria-label="services select"
                        onChange={(e) => setServiceId(e.target.value)}
                       >
                        <option defaultValue>Select your tour</option>
                        {services.map(service => (
                          <option key={service.id} value={service.id}>{service.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="transferInput" className="form-label">
                        Transfer Type
                      </label>
                      <select 
                        id="transferInput"
                        className="form-select"
                        aria-label="Transfer type select"
                        onChange={(e) => setTransferType(e.target.value)}
                       >
                        <option defaultValue>Select transfer type</option>
                        <option value="Shared Transfer">Shared Transfer</option>
                        <option value="Private Transfer">Private Transfer</option>  
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="subjectInput" className="form-label">
                        Subject
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subjectInput"
                        placeholder="Enter subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="messageInput" className="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="messageInput"
                        rows="3"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-12 col-md-6">
                    <button type="submit" className="btn btn-primary" disabled={isBooking || isError}>
                      {!isBooking && <span role="status">Book</span>}
                      {isBooking && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                      {isBooking && <span className="ms-2" role="status">Booking</span>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Booking;
