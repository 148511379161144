import { Container } from "react-bootstrap";
import "./tour.css";

const Tour = () => {
  return (
    <Container>
      <div>Tour</div>
    </Container>
  );
};
export default Tour;
