import { Container } from "react-bootstrap";
import "./contactus.css";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [name, setName]= useState('');
  const [email, setEmail]= useState('');
  const [phone, setPhone]=useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage]= useState('');
  const [isSending, setIsSending] = useState(false);
  const [isError, setIsError] = useState(false);

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
  }

  useEffect(() => {
    if(!name || !email || !phone || !subject || !message){
      setIsError(true)
    }else{
      setIsError(false)
    }
  }, [name, email, phone, subject, message]);

  const submitHandler = async(e) => {
    e.preventDefault();
    setIsSending(true);
    const formData = {
      name: name,
      email: email,
      phone: phone,
      subject: subject,
      message: message
    };
    console.log(formData)
    try{

      const response = await axios.post("api/contacts", formData);

      console.log(response.data);
      if(response?.data?.status === true){
        toast.success("Message sent successfully!")
      }
    }catch(error){
      console.log(error);
      toast.error(error?.response?.data?.message)
    }

    // console.log(response)
    setIsSending(false);
    resetForm();
  }

  return (
    <section className="section">
      <Container>
        <div className="row gap-4 gap-lg-2">
          <div className="col-12 col-lg-5 align-self-center">
            <div className="contact-us-info">
              <div>
                <h3>Operating Hours:</h3>
                <p>We're here for you 24/7</p>
              </div>
              <div>
                <h3>ADDRESS:</h3>
                <p>
                P.O. box 93512,
                Deira, Dubai, Horal anz.
                </p>
              </div>
              <div>
                <h3>TICKET INFO:</h3>
                <div className="d-flex flex-column gap-2">
                  <div className="contact-links-container">
                    <p>Phone:</p>
                    <a className="link contact-link" href="tel:+971 56 372 5439">+971 56 372 5439</a>
                  </div>
                  <div className="contact-links-container">
                    <p>Email:</p>
                    <a className="link contact-link" href="mailto:abidzahid979@gmail.com">abidzahid979@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="contact-us-form">
              <h2 className="mb-0 primary-heading">Contact Us</h2>
              <p className="mb-0">
                Contact us now to book your seamless and reliable tour
                experience! Experience top-quality service with our premium Tour.
              </p>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="fullNameInput" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullNameInput"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="emailInput" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="phoneInput" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneInput"
                        placeholder="Enter your phone no."
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="subjectInput" className="form-label">
                        Subject
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subjectInput"
                        placeholder="Enter subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="messageInput" className="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="messageInput"
                        rows="3"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <button type="submit" className="btn btn-primary" disabled={isSending || isError}>
                      {!isSending && <span role="status">Send Message</span>}
                      {isSending && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                      {isSending && <span className="ms-2" role="status">Sending</span>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default ContactUs;
