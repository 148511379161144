import { Container } from "react-bootstrap";
import "./service.css";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { useEffect, useState } from "react";
import axios from "../../api/axios";

const Service = () => {
  const [services, setServices] = useState([]);
  const serviceAPI = 'api/service-list';

  useEffect(() => {
    const fetchServices = async () => {
      const response = await axios.get(serviceAPI)
        setServices(response.data.data)
    }

    fetchServices();
  }, [serviceAPI]);

  return (
    <Container>
      <h2 className="services-page-title">Services</h2>
      <div className="row justify-content-center my-3">
        <div className="col-12 col-lg-8 text-center">
          <h2 className="primary-heading">
            Discover MA <span className="text-primary">Travel</span> and <span className="text-primary">Tourism </span>
            Tours!
          </h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="grid col-auto my-3">
          {services.map((service) => (
            <ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service?.images[0]?.image}`} />
          ))}
        </div>
      </div>
    </Container>
  );
};
export default Service;
