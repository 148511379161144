import {
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaInstagram,
} from "react-icons/fa";

import classes from "./footer.module.css";
import logo from "../../assets/MA-travel-and-tourism-logo.png"
const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className="container">
        <div className={classes.footerContent}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="MA Travel and Tourism logo" />
          </div>
          <ul className={classes.socialList}>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="https://www.facebook.com/profile.php?id=61550280841388&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                <FaFacebookF />
              </a>
            </li>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="www.twitter.com" target="_blank" rel="noreferrer">
                <FaTwitter />
              </a>
            </li>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="www.ggogle.com" target="_blank" rel="noreferrer">
                <FaGooglePlusG />
              </a>
            </li>
            <li className={classes.socialItem}>
              <a className={`link ${classes.socialLink}`} href="https://www.instagram.com/ma.travelandtourism/" target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Footer;
