import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

function PopoverPositioned({placement, id, children, popoverText, variant="" }) {
  return (
    <OverlayTrigger
      key={id}
      placement={placement}
      overlay={
        <Popover id={`popover-positioned-${placement}`}>
          <Popover.Body>
            {popoverText}
          </Popover.Body>
        </Popover>
      }
    >
      <Button variant={variant}>{children}</Button>
    </OverlayTrigger>
  );
}

export default PopoverPositioned;