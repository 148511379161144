import classes from "./home.module.css";

import image1 from "../../assets/pexels-denys-gromov-4781948.jpg";
import image2 from "../../assets/pexels-marcus-herzberg-2086765.jpg";
import image3 from '../../assets/pexels-nextvoyage-1470502.jpg'
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../api/axios";
import TopCities from "../../components/TopCities/TopCities";

const Home = () => {
  const [faqs, setFaqs] = useState([]);
  const [services, setServices] = useState([]);
  const serviceApi = 'api/service-list';
  const faqsApi = 'api/faq-list';

  useEffect(() => {
    const fetchServices = async () => {
      const response = await axios.get(serviceApi)
        setServices(response.data.data)
    }
    fetchServices();
  }, [serviceApi]);

  useEffect(() => {
    const fetchData = async() => {
      const response = await axios.get(faqsApi);

      if(response){
        // console.log(response.data)
        setFaqs(response.data.data)
      }
    }

    fetchData();
  }, [faqsApi])

  return (
    <>
      <section className={classes.heroSection}>
        {/* <img className={classes.backgroundImage} src={background} /> */}
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6"></div>
            <div className={`${classes.heroContainer} col-12 col-lg-6`}>
              <div className={classes.heroContent}>
                <h1 className="primary-heading">
                  WE ARE LEADING TRAVEL AND TOURISM COMPANY IN DUBAI
                </h1>
                <p>
                  MA Travel and Tourism is a state-of-the-art transport rental,
                  passenger transport, and vehicle leasing agency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 align-self-center">
              <h5 className="accent-heading">Travel And Tour Provider</h5>
              <p className="fs-5">
                Elevate your travel dreams with MA Travel and Tourism, your trusted companion for unforgettable journeys in Dubai, Abu Dhabi, and Ras Al Khaimah. We understand that every traveler seeks more than just a trip - they seek a captivating experience that lingers in their hearts forever.
              </p>
              <p className="fs-5">
                From the futuristic marvels of Dubai's skyline to the cultural tapestry of Abu Dhabi and the serene beauty of Ras Al Khaimah's landscapes, we curate each tour with your desires in mind. Our dedicated team ensures that every step of your journey is seamless, personalized, and unforgettable. Let us turn your travel dreams into reality - book your next adventure with MA Travel and Tourism and unlock a world of extraordinary possibilities.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div className={classes.imageContainer}>
                <img
                  className={`${classes.decorImages} ${classes.decorImages1}`}
                  src={image1}
                  alt="vehicle"
                />
                <img
                  className={`${classes.decorImages} ${classes.decorImages2}`}
                  src={image2}
                  alt="vehicle"
                />
                <img
                  className={`${classes.decorImages} ${classes.decorImages3}`}
                  src={image3}
                  alt="vehicle"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <TopCities />
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row gap-3 gap-lg-0">
            <div className="col-12 col-lg-8 mb-3">
              <h2 className="primary-heading">
                Top Experiences in Dubai
              </h2>
              <p>Unveil culture, nature, thrills, and record-breaking encounters beyond imagination. Unleash your desires In Dubai!</p>
            </div>
          </div>
          <div className="mt-3 mt-lg-0">
            <div className=" grid col-auto">
              {services.filter(service => service?.group?.title.toLowerCase() === "dubai" && service.popular === 1).map((service) => 
                ( <ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service.images[0].image}`} />)
              )}
              {services.filter(service => service?.group?.title.toLowerCase() === "dubai" && service.popular === 1).length <= 0 && <div className="d-flex align-items-center justify-content-center"><h5>No Services available yet!</h5></div>}
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row gap-3 gap-lg-0">
            <div className="col-12 col-lg-8 mb-3">
              <h2 className="primary-heading">
                Top Experiences in Abu Dhabi
              </h2>
              <p>Discover the best attractions and activities that the UAE's capital city has to offer.</p>
            </div>
          </div>
          <div className="mt-3 mt-lg-0">
            <div className="grid col-auto">
              {services.filter(service => service?.group?.title.toLowerCase() === "abu dhabi" && service.popular === 1).map  ((service) => 
                  ( <ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service.images[0].image}`} />)
                )}
              {services.filter(service => service?.group?.title.toLowerCase() === "abu dhabi" && service.popular === 1).length <= 0 && <div className="d-flex align-items-center justify-content-center"><h5>No Services available yet!</h5></div>}
            
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row gap-3 gap-lg-0">
            <div className="col-12 col-lg-8 mb-3">
              <h2 className="primary-heading">
                Top Experiences in Ras Al Khaimah
              </h2>
              <p>Explore rich culture, pristine beaches, and unforgettable adventures in this captivating haven In Dubai!</p>
            </div>
          </div>
          <div className="mt-3 mt-lg-0">
            <div className="grid col-auto">
              {services.filter(service => service?.group?.title.toLowerCase() === "ras al khaimah" && service.popular === 1).map((service) => 
                (<ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service.images[0].image}`} />)
              )}
              {services.filter(service => service?.group?.title.toLowerCase() === "ras al khaimah" && service.popular === 1).length <= 0 && <div className="d-flex align-items-center justify-content-center"><h5>No Services available yet!</h5></div>}
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <div className="container">
          <h2 className="text-center">FAQs</h2>
          <div className="row align-items-center justify-content-center mt-3">
            <div className="col-12 col-lg-8">
              <div className="accordion" id="accordionQuestions">
                {faqs.map(faq => (
                  <div key={`faqs_${faq.id}`} className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${faq.id}`} aria-expanded="false" aria-controls={`#collapse_${faq.id}`}>
                        {faq.question}
                      </button>
                    </h2>
                    <div id={`collapse_${faq.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionQuestions">
                      <div className="accordion-body">
                        {faq.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
