import classes from "./serviceCard.module.css";

const ServiceCard = ({ title, image, id }) => {
  return (
    <a href={`/service/${id}`} className={`link ${classes.card}`}> 
        <img src={image} className={classes.cardImage} alt="vehicle"/>
        <div className={classes.cardContent}>
          <h3 className={classes.cardTitle}>{title}</h3>
        </div>
    </a>
  );
};
export default ServiceCard;
