import { FaWhatsapp } from "react-icons/fa";
import classes from "./whatsappButton.module.css";
const WhatsappButton = () => {
  return (
    <div className={classes.whatsappButton}>
      <a
        className={`link ${classes.whatsappLink}`}
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/971563725439?text=Hello! I want to book a transport service."
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};
export default WhatsappButton;
