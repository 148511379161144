import classes from './cityCard.module.css'

const CityCard = ({image, title, id}) => {
  return (
    <a className={`${classes.card}`} href={`/${title}/${id}`}>
      <img src={image} className={classes.cardImage} alt={`${title}'s`}/>
      <h3 className={classes.cardTitle}>{title}</h3>
    </a>
  )
}
export default CityCard