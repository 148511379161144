import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import TopBanner from "./TopBanner/TopBanner";
import Footer from "./Footer/Footer";
import WhatsappButton from "./WhatsappButton/WhatsappButton";
import ExploreMore from "./ExploreMore/ExploreMore";
const Layout = () => {
  return (
    <div className="layout">
      <TopBanner />
      <Header />
      <main>
        <Outlet />
      </main>
      <ExploreMore />
      <Footer />
      <WhatsappButton />
    </div>
  );
};

export default Layout;
