import { useEffect, useState } from 'react';
import './exploreMore.css'
import axios from '../../api/axios';

const ExploreMore = () => {
  const [services, setServices] = useState([]);
  const serviceApi = 'api/service-list';

  useEffect(() => {
    const fetchServices = async () => {
      const response = await axios.get(serviceApi)
        setServices(response.data.data)
    }
    fetchServices();
  }, [serviceApi]);

  return (
    <div className='exploreContainer'>
      <h2>Explore More On MA Travel And Tourism</h2>
      <div className="thing-todo">
        <h3>Things to do Dubai: </h3>
        <ul className="list services_list">
          {services.filter(service => service?.group?.title.toLowerCase() === "dubai").map(service => (
            <li key={service.id} className="services_item"><a href={`/service/${service.id}`} className="link">{service.title}</a></li>
          ))}
        </ul>
      </div>
      <div className="thing-todo">
        <h3>Things to do Abu Dhabi: </h3>
        <ul className="list services_list">
          {services.filter(service => service?.group?.title.toLowerCase() === "abu dhabi").map(service   => (
            <li key={service.id} className="services_item"><a href={`/service/${service.id}`} className="link">{service.title}</a></li>
          ))}
        </ul>
      </div>
      <div className="thing-todo">
        <h3>Things to do Ras Al Khaimah: </h3>
        <ul className="list services_list">
          {services.filter(service => service?.group?.title.toLowerCase() === "ras al khaimah").map(service => (
            <li key={service.id} className="services_item"><a href={`/service/${service.id}`} className="link">{service.title}</a></li>
          ))}
        </ul>
      </div>
    </div>
  )
}
export default ExploreMore