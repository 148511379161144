import classes from "./header.module.css";
import logo from '../../assets/MA-travel-and-tourism-small-logo.png'
import logoIcon from  "../../assets/logo-Icon.png"
import { NavLink } from "react-router-dom";
const Header = () => {
  return (
    <header className={classes.header}>
      <div className="container-fluid container-lg">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img className={classes.logoImage} src={logo} alt="MA Travel and tourism logo icon"/>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  <img className={classes.logoImageIcon} src={logoIcon} alt="MA Travel and tourism logo"/>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body text-center">
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to={"/"}>
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/services"}>
                      Services
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/about-us"}>
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/contact-us"}>
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
                <a href="/booking" className="btn btn-primary">
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Header;
